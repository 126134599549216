@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    :root {
        --color-border: #c1bfbf;
        --color-bg: #ffffff;
        --color-hightlightedText: #01510a;
        --color-headingText: #7c7c7c;
        --color-selectedText: #01510a;
        --color-unselectedText: #6d6d6dcc;
        --color-redText: #970a0c;
        --color-greenText: #01510a;
        --color-greenTextBackground: #01510a33;
        --color-redTextBackground: #970a0c40;
        --color-arrowColorRed: #970a0c;
        --color-arrowColorGreen: #01510a;
        --color-valueColorGreen: #003306;
        --color-valueColorRed: #970a0c;
        --color-snvBackground: #8c8c8c;
        --color-snvText: #fefefe;
        --color-divBorder: #01510a;
        --color-closePositionText: #970a0c99;
        --color-closePositionBackground: #970a0c33;
        --color-tabHeadSelected: #3f3c3c;
        --color-tabHeadUnSelected: #6d6d6dcc;
        --color-orderBookQuantity: #505551;
        --color-searchMarketText: #878686;
        --color-acctStatsText: #524f4f;
        --color-buyBackground: #01510a1a;
        --color-sellBackground: #970a0c1a;
        --color-inputBackground: #8c8c8c4d;
        --color-buyPlaceOrderBG: #01510a40;
        --color-sellPlaceOrderBG: #970a0c40;
        --color-disabledButtonText: #00000080;
        --color-disabledButtonBG: #6a65654d;
        --color-disabledButtonBorder: #8c8c8c;
        --color-openOrderRowBgOdd: #01510a1a;
        --color-openOrderRowBgEven: #01510a0d;
        --color-portfolioBG: #01510a26;
        --color-portfolioChildBG: #01510a4d;
        --color-percentageButtonDisableBG: #8c8c8ccc;
        --color-onboardDisableText: #878787;
        --color-spreadBG: #8c8c8c59;
        --color-divisionBorder: #d7d7d7cc;
        --color-navLeftText: #033c0780;
        --color-tooltip: #36894226;
        --color-hoverTableColor: #01510a4d;
        --color-placeholderText: #00000035;
        --color-chartTopLegend: #3f3c3c80;
    }
    
    .theme-button-color {
        background-image: linear-gradient(45deg, #5c03bc 12.56%, #e536ab 100%);
    }

    .theme-outlined {
        --r: 0.375rem;
        /* radius */
        --b: 2px;
        /* border width */

        background: linear-gradient(45deg, #5c03bc 12.56%, #e536ab 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        color: transparent;
        border-radius: var(--r);
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        position: relative;
        z-index: 0;
        text-decoration: none;
    }

    /* check lined question for the detail of the below code */
    .theme-outlined::before {
        content: '';
        position: absolute;
        z-index: -1;
        inset: 0;
        border: var(--b) solid transparent;
        border-radius: var(--r);
        background: inherit;
        background-origin: border-box;
        background-clip: border-box;
        mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
        -webkit-mask-composite: xor;
        mask-composite: exclude;
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
    }

    .theme-gradient {
        background: linear-gradient(45deg, #5c03bc 12.56%, #e536ab 100%);
    }
}

.module-border-wrap {
    position: relative;
    background: linear-gradient(45deg, #5c03bc 12.56%, #e536ab 100%);
    padding: 1px;
}

.module {
    background: #141820;
    color: white;
    padding: 2rem;
    border-radius: 6px;
}

html {
    font-size: 92%;
}

.sidebar-active {
    background: hsla(0, 0%, 85%, 0.22);
    border-radius: 0px 6px 6px 0px;
}

.nav-left-active {
    @apply rounded-[5.62rem] theme-button-color text-white;
}

.sidebar-open {
    width: 200px;
    transition: all 0.3s;
    z-index: 10;
}

.sidebar-close {
    width: 63px;
    transition: all 0.3s;
}

.sidebar-open-outlet {
    width: calc(100% - 4rem);
    transition: all 0.3s;
    margin-left: -136.5px;
    height: calc(100vh - 6rem);
}

.sidebar-close-outlet {
    width: calc(100% - 3.5rem);
    transition: all 0.3s;
}

.active-navlink-bg {
    background: #01510a4d;
    border-radius: 0px 2px 2px 0px;
}

.hamburger-bg {
    background: rgba(212, 182, 255, 0.1);
}

.simple-border {
    border: 1px solid white;
}

.pagination-container {
    width: 23rem;
    height: 3.28rem;
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 10px;
}

.pagination-num {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2.48rem;
    width: 2.48rem;
    border: 1px solid #01510a;
    border-radius: 6px;
}

.gradient-text-color {
    font-size: 0.87rem;
    background: linear-gradient(45deg, #5c03bc 12.56%, #e536ab 100%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.selected > a {
    background-color: #01510a4d;
}

.orderbook-first-red > div:first-child {
    color: #db434d;
    font-weight: 700;
}

.orderbook-first-green > div:first-child {
    color: #75b587;
    font-weight: 700;
}

.orderbook-first-red > div:first-child:hover {
    color: #fff;
    font-weight: 700;
}

.orderbook-first-green > div:first-child:hover {
    color: #fff;
    font-weight: 700;
}

/* Set this value to .1s if animation needed */
.dashboard-open-close {
    transition: all 0s;
}

.get-token-text {
    background: linear-gradient(45deg, #5c03bc 12.56%, #e536ab 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-family: 'Inter';
    font-style: normal;
    font-size: 16px;
    font-weight: 500;
    /* margin-right: 10px; */
}

.product-name {
    font-family: 'Inter';
    font-weight: 400;
}

.arrow {
    filter: invert(65%) sepia(72%) saturate(4804%) hue-rotate(342deg) brightness(86%);
}

.arrow2 {
    filter: invert(65%) sepia(49%) saturate(278%) hue-rotate(84deg) brightness(200%) contrast(110%);
}

.basicButton {
    background: linear-gradient(45deg, #5c03bc 12.56%, #e536ab 100%);
}

.basicBorder {
    border: 1px solid linear-gradient(45deg, #5c03bc 12.56%, #e536ab 100%);
}

.custom-tooltip {
    border: 1px solid black;
}

.blur-background {
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.1) 100%);
    backdrop-filter: blur(5px);
    border: 1px solid #000000;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.font-inter {
    font-family: 'Inter';
}

.gradient-text {
    background: linear-gradient(45deg, #5c03bc 12.56%, #e536ab 100%);
}

.ant-picker {
    padding: 0px 0px;
    background-color: #11141b;
    border-color: #2b2b45;
    width: 115px;
    height: 32px;
}

.ant-picker-range {
    background-color: #ffffff;
}

.ant-picker-container {
    background-color: #11141b;
}

.ant-picker-dropdown .ant-picker-range-arrow::before {
    background: #0e1017;
}

.ant-picker-dropdown .ant-picker-cell {
    color: white;
}

.ant-picker-dropdown .ant-picker-content th {
    color: white;
}

.ant-picker-dropdown .ant-picker-header .ant-picker-header-next-btn {
    color: white;
}

.ant-picker-dropdown .ant-picker-header .ant-picker-header-next-btn:hover {
    color: white;
}

.ant-picker-dropdown .ant-picker-header .ant-picker-header-super-next-btn {
    color: white;
}

.ant-picker-dropdown .ant-picker-header .ant-picker-header-super-next-btn:hover {
    color: white;
}

.ant-picker-dropdown .ant-picker-header .ant-picker-header-prev-btn {
    color: white;
}

.ant-picker-dropdown .ant-picker-header .ant-picker-header-prev-btn:hover {
    color: white;
}

.ant-picker-dropdown .ant-picker-header .ant-picker-header-super-prev-btn {
    color: white;
}

.ant-picker-dropdown .ant-picker-header .ant-picker-header-super-prev-btn:hover {
    color: white;
}

.ant-picker-dropdown .ant-picker-panel-container {
    background: #0e1017;
}

.ant-picker-dropdown .ant-picker-header {
    color: white;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background: #5c03bc;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: #e536ab;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: #010202;
}

.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before {
    background: black;
}

.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before {
    background: #c8dfff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-start-single)::before {
    background: black;
}

.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):hover {
    background: black;
}

.ant-picker-dropdown
    .ant-picker-cell-in-view
    .ant-picker-cell-range-end:not(
        .ant-picker-cell-range-end-single
    ).ant-picker-cell-range-hover-end::before {
    background: black;
}

.ant-picker-dropdown
    .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: black;
}

.ant-picker:hover {
    border-color: #2b2b45;
}

.ant-picker-focused {
    border-color: #2b2b45;
}

.ant-picker-range .ant-picker-input {
    z-index: 1;
    background: #11151d;
    border: 1px solid #2b2b45;
    border-radius: 4px;
    color: white;
    padding: 7px 9px;
    width: 125px;
    height: 36.6px;
    font-size: 14px;
    font-weight: 400;
    color: white;
    font-family: 'Inter';
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-picker .ant-picker-input {
    width: 120%;
}

.ant-picker .ant-picker-input > input {
    color: white;
    padding: 9px 9px;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter';
    width: min-content;
}

.custom-range-picker .ant-picker-separator {
    color: red;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background: #5c03bc;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
    background: #010202;
}

.ant-picker-panels {
    column-gap: 20px;
}

th {
    display: table-cell;
    vertical-align: inherit;
    font-weight: normal !important;
    text-align: -internal-center;
}

.deleted-row {
    animation: blink 300ms linear 1, collapse 300ms linear 1 forwards;
    border: 1px solid #db434d;
}

@keyframes blink {
    0% {
        border-color: #db434d;
    }

    100% {
        border-color: transparent;
    }
}

@keyframes collapse {
    0% {
        opacity: 1;
        max-height: 500px;
    }

    100% {
        opacity: 0;
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.collapsing-row {
    overflow: hidden;
    opacity: 1;
    max-height: 500px;
}

.collapsing-row.collapsed {
    opacity: 0;
    max-height: 0;
    padding: 0;
    margin: -4px 0px;
    border: none;
    transition: opacity 300ms ease-in-out, padding 0s, margin 0s, border 0s;
}

@keyframes blink-red {
    0% {
        color: #db434d;
    }

    50% {
        color: white;
    }

    100% {
        color: #db434d;
    }
}

@keyframes blink-green {
    0% {
        color: #75b587;
    }

    50% {
        color: white;
    }

    100% {
        color: #75b587;
    }
}

@keyframes blink-purple {
    0% {
        color: #ae8dd6;
    }

    50% {
        color: white;
    }

    100% {
        color: #ae8dd6;
    }
}

.blink-red {
    animation: blink-red 1500ms linear;
}

.blink-green {
    animation: blink-green 1500ms linear;
}

.blink-purple {
    animation: blink-green 1500ms linear;
}

@keyframes shortOrderbook-blink {
    0% {
        opacity: 1;
        color: #fdfcfc;
    }

    50% {
        opacity: 0;
        color: #db434d;
    }

    100% {
        opacity: 1;
        color: #fdfcfc;
    }
}

.shortOrderbook-blink {
    animation: shortOrderbook-blink 1s linear normal forwards;
}

.circle {
    background: conic-gradient(from 0deg, var(--color) var(--margin), transparent 0);
}

@keyframes longOrderbook-blink {
    0% {
        opacity: 1;
        color: #fdfcfc;
    }

    50% {
        opacity: 0;
        color: #75b587;
    }

    100% {
        opacity: 1;
        color: #fdfcfc;
    }
}

.longOrderbook-blink {
    animation: longOrderbook-blink 1s linear normal forwards;
}

@keyframes dots-animation {
    0% {
        opacity: 0;
    }

    33.3% {
        opacity: 1;
    }

    66.6% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.dots-container {
    display: flex;
    justify-content: center;
}

.dot {
    animation: dots-animation 1s ease-in-out infinite;
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.5s;
}

.dot:nth-child(3) {
    animation-delay: 1s;
}

.dot::after {
    content: '.';
}

div.ant-collapse > div.ant-collapse-item-active > div.ant-collapse-header {
    display: none !important;
}

.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0px 0px !important;
}

.ant-collapse-header {
    padding: 0px 12px !important;
}

.MuiTooltip-arrow::before {
    color: #01510a26 !important;
    backdrop-filter: blur(100px) !important;
}

/* @tailwind utilities; */
.tab-list {
    display: flex;
    column-gap: 30px;
    margin: 15px 0px;
}

/* datepicker css */
/* outer container of date input field */
.MuiStack-root {
    background-color: #11151d;
    border: 1px solid #2b2b45;
    border-radius: 5px;
}

/* all icons */
.MuiSvgIcon-root {
    color: white;
}

/* input field */
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    color: white !important;
    height: 30px !important;
    width: 128.5% !important;
    background-color: #11151d !important;
    cursor: pointer !important;
    font-size: 14px !important;
    font-family: 'Inter' !important;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    color: white !important;
    width: 128.5% !important;
    background-color: #11151d !important;
    cursor: pointer !important;
    font-size: 14px !important;
    font-family: 'Inter' !important;
}

/* properties of date in input field */
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 14px !important;
    font-family: 'Inter' !important;
    cursor: pointer !important;
}

/* for year */
.css-1beqopj-MuiPickersYear-yearButton {
    color: white !important;
}

/* for month */
.css-bw88rr-MuiPickersMonth-monthButton {
    color: white !important;
}

/* dates modal */
.MuiDateCalendar-root {
    background-color: #11151d;
}

/* selct month and year ----->>>  */
.MuiPickersCalendarHeader-labelContainer {
    color: white;
    background-color: #11151d;
}

/* weekdays name outside */
.MuiDayCalendar-header {
    background-color: #11151d;
}

/* weekdays name */
.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel {
    color: white !important;
}

/* rest dates in the month */
.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root {
    color: white !important;
    background-color: #11151d !important;
}

/* current date */
.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
    color: white !important;
    background-color: #11151d !important;
    border: 1px solid #1677ff !important;
    border-radius: 4px !important;
}

.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: #5c03bc !important;
    border: 1px solid #5c03bc !important;
    border-radius: 4px !important;
}

/* for disabled the color of future dates */
.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled {
    opacity: 0.3 !important;
}

/* for disabled the color of future months */
.css-bw88rr-MuiPickersMonth-monthButton.Mui-disabled {
    opacity: 0.3 !important;
}

/* for disabled the color of future years */
.css-1beqopj-MuiPickersYear-yearButton.Mui-disabled {
    opacity: 0.3 !important;
}

/* development css of datepicker */
.css-1exv9rf {
    background-color: #11151d !important;
    border: 1px solid #2b2b45 !important;
    border-radius: 5px !important;
    height: 30px !important;
    display: flex !important;
    flex-direction: column !important;
    overflow: auto !important;
    padding-top: 8px !important;
}

.css-i44wyl {
    color: white !important;
    height: 30px !important;
    width: 100% !important;
    background-color: #11151d !important;
    cursor: pointer !important;
    font-size: 14px !important;
    font-family: 'Inter' !important;
    margin-top: 5px !important;
}

.css-1bn53lx {
    margin-top: -3px !important;
    position: absolute !important;
    color: white !important;
    height: 30px !important;
    width: 100% !important;
    background-color: #11151d !important;
    cursor: pointer !important;
    font-size: 14px !important;
    font-family: Inter !important;
}

/* properties of date in input field */
.css-1uvydh2 {
    color: white !important;
    margin-top: -1px !important;
    font-size: 14px !important;
    font-family: 'Inter' !important;
    cursor: pointer !important;
    width: 125% !important;
    background-color: #11151d !important;
    min-width: 96px !important;
    border: none !important;
}

/* for calendar icon */
.css-vubbuv {
    margin-top: -1px !important;
}

.css-10qkmc3 {
    color: white !important;
}

/* for rest dates */
.css-1hrukl9 {
    color: white !important;
}

/* for year */
.css-1bqdsf2 {
    color: white !important;
}

/* for disabled the color of year */
.css-1bqdsf2.Mui-disabled {
    opacity: 0.3 !important;
}

.css-k9reix {
    color: white !important;
}

.css-uetvsz.Mui-disabled {
    color: white !important;
    opacity: 0.3 !important;
}

.css-1hrukl9.Mui-disabled:not(.Mui-selected) {
    opacity: 0.3 !important;
}

.css-4ggly9.Mui-selected {
    background-color: #5c03bc !important;
    border: 1px solid #5c03bc !important;
    border-radius: 4px !important;
}

.css-1hrukl9.Mui-selected {
    color: white !important;
    background-color: #11151d !important;
    border: 1px solid #1677ff !important;
    border-radius: 4px !important;
}

.css-4ggly9:not(.Mui-selected) {
    color: white !important;
    background-color: #5c03bc !important;
    border: 1px solid #5c03bc !important;
    border-radius: 4px !important;
}

.MuiBox-root-10 {
    top: 50% !important;
    left: 50% !important;
    width: 4px !important;
    border: 2px solid rgb(0, 0, 0);
    padding: 32px !important;
    position: absolute !important;
    transform: translate(-50%, -50%) !important;
    background-color: transparent !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    height: 10px !important;
}

.MuiBox-root-1,
.MuiBox-root-2,
.MuiBox-root-3,
.MuiBox-root-4,
.MuiBox-root-5,
.MuiBox-root-6,
.MuiBox-root-7,
.MuiBox-root-8,
.MuiBox-root-9,
.MuiBox-root-10,
.MuiBox-root-11 {
    height: 10px !important;
}

.MuiBox-root {
    height: 10px !important;
}

.custom-dropdown {
    position: relative;
    display: inline-block;
}

.button {
    width: 112px;
    height: 38px;
    border: 1px solid #373f4e;
    background-color: #232a37;
    color: white;
    cursor: pointer;
    position: relative;
    top: 78px;
    right: 11px;
    z-index: 10;
}

.options {
    color: white;
    background-color: #232a37;
    padding: 5.5px 11px;
    margin: 0px;
    cursor: pointer;
    margin-right: 27px;
    border-radius: 22px;
}

.custom-dropdown-content {
    position: relative;
    top: 70px;
    right: 11px;
    background-color: #232a37;
    margin-top: 5px;
    display: flex;
    padding: 7px 0px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #373f4e;
    width: 112px;
    border-radius: 0px 0px 22px 22px;
    color: white;
}

.MuiSnackbar-anchorOriginTopRight {
    top: 72px !important;
    left: auto !important;
    right: 30px !important;
}

.tab-list {
    display: flex;
    column-gap: 30px;
    margin: 15px 0px;
}

.tab-list-item {
    color: white;
    font-weight: 500;
    font-size: 20px;
}

.sidebar-tab-list-items {
    color: #2e323a;
    padding: 5px 0;
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
}

:first-child.sidebar-tab-list-items {
    border-right: 1px solid #2b2c43;
}

.sidebar-tab-list-active {
    color: white;
}

input[type='number'] {
    background-color: #11151d;
    color: #ffff;
}

input[type='number']:focus {
    outline: 1px solid transparent;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.side-scrollbar {
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
}

.side-scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #101111;
    border-radius: 10px;
    background-color: #101111;
}

.side-scrollbar::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #101111;
    /* Hide scrollbar for Chrome, Safari and Opera */
    display: none;
}

.side-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #101111;
}

.side-scrollbar::-webkit-scrollbar:hover {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #bebebe;
}

.scrollBarDiv::-webkit-scrollbar {
    width: 4px;
}

.scrollBarDiv::-webkit-scrollbar-track {
    background: #333;
    border-radius: 20px;
}

.scrollBarDiv::-webkit-scrollbar-thumb {
    background: #777;
    border-radius: 20px;
}

.scrollBarDiv::-webkit-scrollbar-thumb:hover {
    background: #555;
}



.prose :where(ul > li):not(:where([class~='not-prose'] *))::marker {
    font-size: 20px;
}




.prose :where(ul > li):not(:where([class~='not-prose'] *))::marker {
    font-size: 20px;
}

@keyframes scaling {
    0%,
    100% {
        transform: scale(0.5);
        background-color: #ffffff;
    }

    40% {
        transform: scale(0.4);
        background-color: #ffffff;
        opacity: 40%;
    }

    50% {
        transform: scale(0.3);
        background-color: #ffffff;
    }
}

.loading-dots {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    animation: scaling 1s ease-in-out infinite;
    display: inline-block;
    margin: 0px 1px;
}

#first-dot {
    animation-delay: 0.1s;
}

#second-dot {
    animation-delay: 0.3s;
}

#third-dot {
    animation-delay: 0.5s;
}


.gridContainer {
    background: #10141b;
    display: grid;
    grid-template-columns: repeat(auto-fill, 1fr);
    align-items: center;
    height: 202px;
    width: 359px;
}

.copiedIcon {
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0.1;
    }

    100% {
        opacity: 1;
    }
}

.modalBlurBackground2 {
    backdrop-filter: blur(3px);
}

.MuiModal-backdrop {
    background: linear-gradient(110deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.1) 100%) !important;
    backdrop-filter: blur(5px) !important;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-dropdown .ant-dropdown-menu {
    padding-right: 21px;
}

.allButtonClickAnimation:active {
    transform: scale(0.91);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.closePositionClickAnimation:active {
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}

.allButtonClickAnimation:disabled, .closePositionClickAnimation:disabled {
    background: #262b33;
    cursor: not-allowed;
    transform: none;
}

.amountLMValues {
    font-size: 14px;
    color: #5b626f;
    padding-top: 8px;
    padding-left: 8px;
}

.confirmTradeLMVales {
    font-size: 14px;
    color: '#5B626F';
    padding: 4px 0px 4px 0px;
    font-weight: normal;
    font-family: 'Inter';
}

.historyTradeLM {
    font-size: 14px;
    font-family: 'Inter';
}

.marketPriceModalLMValues {
    font-size: 12px;
    font-family: 'Inter';
    font-weight: normal;
}

.sidebar-trade-marketButtonType {
    @apply text-sm font-semibold text-center;
}

.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root {
    color: white !important;
}

.css-jgls56-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
    color: white !important;
}

/* for disabled the color of date */
.css-1u23akw-MuiButtonBase-root-MuiPickersDay-root.Mui-disabled:not(.Mui-selected) {
    opacity: 0.3 !important;
}

/* for disabled the color of year */
.css-innj4t-MuiPickersYear-yearButton.Mui-disabled {
    opacity: 0.3 !important;
}

.css-innj4t-MuiPickersYear-yearButton {
    color: white !important;
}

.global-ribbon {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}

.react-loading-skeleton::after {
    background-image: linear-gradient(
        91deg,
        rgba(255, 255, 255, 0) 0.49%,
        rgba(255, 255, 255, 0.4) 50.49%,
        rgba(255, 255, 255, 0) 99.45%
    ) !important;
    border-radius: 10px !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #2b2b45 !important;
}

.css-y1th2b {
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #2b2b45 !important;
}

.css-1v4ccyo.Mui-error .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-width: 0px !important;
    border-style: none !important;
}

.guide-toolip-shadow {
    box-shadow: 0px 4px 10px 0px #ffffff33;
}

.input-toggle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    border: 1px solid #fff;
    transition: 0.3s;
    border-radius: 30px;
}

.input-toggle:before {
    position: absolute;
    content: '';
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 2.6px;
    background-color: #fff;
    border-radius: 50%;
    transition: 0.3s;
}

.toggle-box:checked + span {
    border: 1px solid white;
}

.toggle-box:checked + span:before {
    transform: translateX(20px);
}

.confirmModalFixScreen {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* class for Learn More on connect a wallet box while connect metamask */
.iekbcc0 {
    color: white;
}

.allButtonHoverAnimation {
    overflow: hidden;
    position: relative;
    z-index: 20;
}

.allButtonHoverAnimation:after {
    background: #fff;
    content: '';
    height: 155px;
    left: -75px;
    opacity: 0.2;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
}

.allButtonHoverAnimation:hover {
    &:after {
        left: 120%;
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    }
}

.css-15a0tn7:not(.Mui-selected) {
    color: white !important;
    background-color: #11151d !important;
    border: 1px solid #1677ff !important;
    border-radius: 4px !important;
}

.css-6exafu.Mui-disabled:not(.Mui-selected), .css-rhiqj0.Mui-disabled {
    opacity: 0.3 !important;
}

.css-6exafu, .css-rhiqj0 {
    color: white !important;
}

.iekbcc0 {
    color: black;
}

@tailwind utilities;
